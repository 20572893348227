.LoginContainer {
  padding: 0 30px;
  height: 100%;
  background: url(../../assets/images/login-tablet.png) no-repeat center center;
  background-size: cover;
  display: flex;
  justify-content: center;

  .LoginContainer-content {
    max-width: 500px;
    flex: 1;
  }
}

.LoginForm {
  padding-top: 50px;

  .ResetPassword {
    text-decoration: none;
    display: block;
    margin-top: 40px;
  }
}
