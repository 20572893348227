.App {
  height: 100%;

  .CircularProgressLoader {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: center;
  }
}
