.MobileHeader {
  padding: 40px 0;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex: none;

  &.WithBackground {
    padding: 45px 30px 20px;
    background: url(../../../assets/images/mobile-header.png) no-repeat center center;
    background-size: cover;
  }

  &.Padded {
    padding-top: 160px;
    padding-bottom: 30px;
  }

  .Logo {
    flex: 1;

    img {
      width: 100%;
    }
  }
}

@media only screen and (min-width:32em) {
  .MobileHeader {
    .Logo {
      flex: 1;

      img {
        width: auto;
      }
    }
  }
}

@media only screen and (min-width:60em) {
	.MobileHeader {
    &.WithBackground {
      background-image: url(../../../assets/images/tablet-header.png);
    }
  }
}
