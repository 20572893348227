.DataTable {
  .DataTableHeader {
    text-align: right;
  }

  .TableRow-archived {
    opacity: 0.75;
  }

  .TableRow-hover {
    .DataTableActions {
      visibility: hidden;
    }

    &:hover {
      .DataTableActions {
        visibility: visible;
      }
    }
  }
}
