.AdminNotificationsContainer {
  .ExtraInfo {
    margin-top: 6px;
    display: block;
    font-weight: 500;
  }

  .AdminNotificationsToolbar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }
}
