.MobileContent {
  padding: 30px;
  flex: auto;
  overflow-y: auto;
}

.MobileTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
}
