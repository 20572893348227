.Administration {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.AdminNavigation {
  display: flex;
}

.AdministrationContent {
  display: flex;
  flex: auto;
}

.AdministrationInnerContent {
  padding: 30px;
  flex: 1;
  overflow: auto;
}
