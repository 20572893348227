.NewNotificationFab {
  position: absolute;
  bottom: 0;
  margin: 20px;
  right: 0;
}

.NotificationList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.NotificationItem {
  display: flex;
  margin-bottom: 20px;
}

.NotificationData {
  flex: 1;
}

.NotificationData {
  > * {
    margin-bottom: 10px !important;
  }
}

.NotificationData {
  :last-child {
    margin-bottom: 0 !important;
  }
}

.NotificationActions {
  width: 20px;
}

.UserNotificationsContainer,
.NewNotificationContainer,
.EditNotificationContainer {
  height: 100%;
  display: flex;
  flex-flow: column;
}

.UserNotificationsContainer {
  .LoadMore {
    text-align: center;
  }
}
